<template>
    <div>
        <!-- 今日待診 & 未來待診 & 聊天室訊息 & 待辦事項 -->
        <vs-row>
            <vs-col vs-lg="3" vs-sm="3" vs-xs="12" v-for="(data, index) in homeSettingArray" :key="index">
                <div class="cd-card-group" @click="$router.push(data.headerUrl)">
                    <vs-card class="cd-home-small" fixedHeight actionable>
                        <vs-row class="cd-card">
                            <vs-col class="title" vs-type="flex" vs-justify="flex-start" vs-align="center">
                                <h5>{{ $t('home.' + data.title) }}</h5>
                            </vs-col>
                            <vs-col class="main" vs-type="flex" vs-justify="center" vs-align="center">
                                <div class="flex items-end">
                                    <p class="data" :class="data.title == 'todayAppointment' ? 'text-danger' : ''">{{ data.total }}</p>
                                    <p class="unit">{{ $t('home.' + data.unit) }}</p>
                                </div>
                            </vs-col>
                            <vs-col class="note" vs-type="flex" vs-justify="flex-end" vs-align="center">
                                {{ data.previewMsg }}
                            </vs-col>
                        </vs-row>
                    </vs-card>
                </div>
            </vs-col>
        </vs-row>
        <vs-row>
            <!-- 本日概況 -->
            <vs-col vs-lg="4" vs-sm="4" vs-xs="12">
                <div class="cd-card-group">
                    <vs-card class="cd-home-median">
                        <vs-row class="cd-card">
                            <vs-col class="title" vs-type="flex" vs-justify="flex-start" vs-align="center">
                                <h5>{{ $t('home.todayGeneralIdea') }}</h5>
                            </vs-col>
                            <vs-col>
                                <vue-apex-charts type="bar" height="240" :options="todayQueueOptions" :series="todayQueueSeries" v-if="todayQueueSeries" />
                            </vs-col>
                        </vs-row>
                    </vs-card>
                </div>
            </vs-col>
            <!-- 本週概況 -->
            <vs-col vs-lg="8" vs-sm="8" vs-xs="12">
                <div class="cd-card-group">
                    <vs-card class="cd-home-median">
                        <vs-row class="cd-card">
                            <vs-col class="title" vs-type="flex" vs-justify="flex-start" vs-align="center">
                                <h5>{{ $t('home.weekGeneralIdea') }}</h5>
                            </vs-col>
                            <vs-col>
                                <vue-apex-charts type="bar" height="240" :options="weekQueueOptions" :series="weekQueueSeries" v-if="weekQueueSeries" />
                            </vs-col>
                        </vs-row>
                    </vs-card>
                </div>
            </vs-col>
        </vs-row>
        <!-- 日曆 -->
        <vs-row>
            <vs-col>
                <div class="cd-card-group">
                    <vs-card>
                        <vs-row class="cd-card">
                            <vs-col class="cd-form-group">
                                <h5>{{ $t('home.monthGeneralIdea') }}</h5>
                            </vs-col>
                            <vs-col class="cd-form-group" vs-type="flex" vs-justify="center">
                                <span class="cd-p-normal cd-p-pointer sm:mr-20 mr-2" :style="monthlySelection == 1 ? isSelectedColorStyle : ''" @click="monthlySelection = 1">{{ $t('home.reservationTotal') }}</span>
                                <span class="cd-p-normal cd-p-pointer sm:mr-20 mr-2" :style="monthlySelection == 2 ? isSelectedColorStyle : ''" @click="monthlySelection = 2">{{ $t('home.checkInTotal') }}</span>
                                <span class="cd-p-normal cd-p-pointer" :style="monthlySelection == 3 ? isSelectedColorStyle : ''" @click="monthlySelection = 3">{{ $t('home.finishTotal') }}</span>
                            </vs-col>
                        </vs-row>
                        <el-calendar>
                            <template slot="dateCell" slot-scope="{ data }">
                                <p>
                                    {{ data.day.split('-').slice(2).join('-') }}
                                </p>
                                <div class="text-center" v-if="calCountByMonth(data.day.split('-').slice(0).join('-'))">
                                    <p class="fontStyle">
                                        <font class="text-primary">{{ calCountByMonth(data.day.split('-').slice(0).join('-')) }}</font>
                                    </p>
                                </div>
                            </template>
                        </el-calendar>
                    </vs-card>
                </div>
            </vs-col>
        </vs-row>
    </div>
</template>
<script>
import { getCountByWeek, getCountByMonth, changePasswordStatus } from '@/api/user'
import VueApexCharts from 'vue-apexcharts'
import homeSetting from './apps/json/homesetting.json' //儀錶板格式設定檔
export default {
    data() {
        return {
            monthlyAppoint: [],
            monthlyCheck: [],
            monthlyFinish: [],
            monthlyDate: [],
            monthlySelection: 1, // 1:Appoint | 2:Check | 3:Finish
            isSelectedColorStyle: {
                color: '#003f8e',
                padding: '0.6em',
                borderBottom: '3px solid #003f8e',
            },
            year: 0,
            month: '',
            date: '',
            timelineData: [],
            todayQueueOptions: {
                grid: {
                    yaxis: {
                        lines: {
                            show: false,
                        },
                    },
                    padding: {
                        left: 0,
                        right: 0,
                    },
                },
                chart: {
                    id: 'today-queue-chart',
                },
                xaxis: {
                    categories: [this.$t('home.reservationTotal'), this.$t('home.checkInTotal'), this.$t('home.finishTotal')],
                },
                yaxis: {
                    type: 'numeric',
                    labels: {
                        formatter: function (v) {
                            return v.toFixed(0)
                        },
                    },
                },
                colors: ['#7f9fc6', '#416faa', '#003f8e'],
                fill: {
                    colors: ['#7f9fc6', '#416faa', '#003f8e'],
                    opacity: [0.5, 0.75, 1],
                },
                plotOptions: {
                    bar: {
                        distributed: true,
                    },
                },
            },
            todayQueueSeries: [
                {
                    name: this.$t('home.peopleTotal'),
                    data: [],
                },
            ],
            weekQueueOptions: {
                grid: {
                    yaxis: {
                        lines: {
                            show: false,
                        },
                    },
                },
                chart: {
                    id: 'week-queue-chart',
                },
                xaxis: {
                    categories: [this.$t('home.Mon'), this.$t('home.Tue'), this.$t('home.Wed'), this.$t('home.Thu'), this.$t('home.Fri'), this.$t('home.Sat'), this.$t('home.Sun')],
                },
                yaxis: {
                    type: 'numeric',
                    labels: {
                        formatter: function (v) {
                            return v.toFixed(0)
                        },
                    },
                },
                colors: ['#7f9fc6', '#416faa', '#003f8e'],
                fill: {
                    colors: ['#003f8e', '#003f8e', '#003f8e'],
                    opacity: [0.5, 0.75, 1],
                },
            },
            weekQueueSeries: [
                {
                    name: this.$t('home.reservationTotal'),
                    data: [],
                },
                {
                    name: this.$t('home.checkInTotal'),
                    data: [],
                },
                {
                    name: this.$t('home.finishTotal'),
                    data: [],
                },
            ],
        }
    },
    async created() {
        // 取儀錶板格式設定檔
        this.$store.commit('SET_HOMESETTING', homeSetting)
    },
    async mounted() {
        let date = new Date()
        this.year = date.getFullYear()
        this.month = date.getMonth() + 1
        this.month = this.month < 10 ? '0' + this.month : '' + this.month
        this.date = date.getDate()
        this.setMainSidebarQuantityTips()
        await this.getCountByWeek()
        await this.getCountByMonth(this.year, this.month)
        await this.$store.dispatch('fetchProfile')
        await this.check_reset_password_or_not()
        await changePasswordStatus()
        await this.check_doctorenable()
        // this.$store.dispatch('fetchApptUndoneQuantity', this.partnerid)
        await this.$store.dispatch('getTodayQueuesUndoneQuantity')
        // 上個月 EventListener
        this.$nextTick(() => {
            let prevBtn = document.querySelector('.el-calendar__button-group .el-button-group>button:nth-child(1)')
            prevBtn.addEventListener('click', () => {
                this.LastMonth()
                this.getCountByMonth(this.year, this.month)
            })
        })
        // 下個月 EventListener
        this.$nextTick(() => {
            let prevBtn = document.querySelector('.el-calendar__button-group .el-button-group>button:last-child')
            prevBtn.addEventListener('click', () => {
                this.NextMonth()
                this.getCountByMonth(this.year, this.month)
            })
        })
    },
    computed: {
        doctorEnable() {
            return this.$store.state.doctorEnable
        },
        uiastaff() {
            return this.$store.getters.uiastaff
        },
        did() {
            return this.$store.getters.did
        },
        partnerid() {
            return this.$store.getters.partner
        },
        allvipmembers() {
            return this.$store.getters.allvipmembers
        },
        todoFilter() {
            return this.$store.getters.callinfofilter
        },
        memberTotal() {
            return this.$store.getters.memberTotal
        },
        device() {
            return this.$store.getters.device
        },
        myProfile: {
            get() {
                return this.$store.getters.profile
            },
        },
        windowWidth() {
            return this.$store.state.windowWidth
        },
        homeSettingArray() {
            var array = _.cloneDeep(this.$store.getters.homeSetting)
            // const today = new Date()

            // let payload = {
            //     partner_id_arr: [this.partnerid],
            //     start_date: today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate(),
            //     end_date: today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate(),
            // }
            // fetchApptUndoneQuantity(payload).then((res) => {
            //     array[0].total = res.data.data[0].total
            // })
            // this.$store.dispatch('getQueuesUndoneQuantity')

            const payload = {}
            payload.sdid = this.did
            payload.partner_id = this.partnerid
            payload.start_date = this.$common.getCurrentDate(1)
            payload.end_date = this.$common.getCurrentDate(99999)
            this.$store.dispatch('getQueuesUndoneQuantity', payload).then((res) => {
                array[1].total = res.data.data.general.total
            })

            // payload.start_date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + (today.getDate() + 1)
            // payload.end_date = today.getFullYear() + 999 + '-' + (today.getMonth() + 1) + '-' + today.getDate()
            // fetchApptUndoneQuantity(payload).then((res) => {
            //     array[1].total = res.data.data[0].total
            // })

            array[0].total = this.$store.state.undoneApptTotal
            array[2].total = this.$store.getters.msgtotal
            array[3].total = this.$store.state.todoutotal
            return array
        },
    },
    methods: {
        // 檢查是否有使用忘記密碼，有的話提醒更改密碼，並給予跳轉頁面選項
        check_reset_password_or_not() {
            if (this.myProfile.password_doctor_reset_status == 1) {
                this.$confirm(this.$t('home.resetPasswordOrNot') + '?', this.$t('popup.alert'), {
                    confirmButtonText: this.$t('popup.confirm'),
                    cancelButtonText: this.$t('popup.cancel'),
                    type: 'warning',
                }).then(() => {
                    this.$router.push({ path: '/setting/profile' })
                })
            }
        },
        //檢查 醫生資格 與 手機驗證 & 跳轉頁面 doctorEnable 0=未審核 1=啟用 2=不符資格 (暫時放這裡，需套用到每個router)
        check_doctorenable() {
            if (this.doctorEnable == 2) {
                console.log('登出 - 不符資格')
                this.$store.dispatch('updateDoctorStatus', 3000)
                this.$store
                    .dispatch('logout')
                    .then(() => {
                        this.$socket.client.emit('login_out')
                    })
                    .catch((error) => {
                        console.log(error)
                    })
                    .then(() => {
                        this.$socket.client.close()
                    })
                // this.$router.push({ path: '/pages/login' })
                // window.location.reload()
            } else if (this.doctorEnable == 0) {
                this.$vs.dialog({
                    title: this.$t('popup.alert'),
                    text: this.$t('home.doctorJoinMsg', { partnerName: this.getPartnerName() }),
                    acceptText: this.$t('popup.confirm'),
                })
                this.$router.push({ path: '/setting/profile' })
            } else if (this.myProfile.mobile == undefined || this.myProfile.mobile == '' || this.myProfile.verify < 3) {
                this.$vs.dialog({
                    title: this.$t('popup.alert'),
                    text: this.$t('home.doctorPhoneVerifyMsg', { partnerName: this.getPartnerName() }),
                    acceptText: this.$t('popup.confirm'),
                })
                this.$router.push({ path: '/setting/profile' })
            }
        },
        // 取每週 預約人數 & 報到人數 & 完成人數
        getCountByWeek() {
            var _self = this
            _self.$vs.loading()
            getCountByWeek()
                .then((res) => {
                    if (res.data.status == 'OK') {
                        let tmpdata = res.data.items
                        let appoint = []
                        let check = []
                        let finish = []
                        tmpdata.forEach(function (v) {
                            appoint.push(v.appoint)
                            check.push(v.check)
                            finish.push(v.finish)
                            if (v.date == _self.year + '-' + _self.month + '-' + _self.date) {
                                _self.todayQueueSeries = [{ data: [v.appoint, v.check, v.finish] }]
                            }
                        })
                        _self.weekQueueSeries = [
                            {
                                data: appoint,
                            },
                            {
                                data: check,
                            },
                            {
                                data: finish,
                            },
                        ]
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
                .then(() => {
                    _self.$vs.loading.close()
                })
        },
        // 取每月 預約人數 & 報到人數 & 完成人數
        getCountByMonth(year, month) {
            var _self = this
            _self.$vs.loading()
            getCountByMonth({ year: year, month: month })
                .then((res) => {
                    if (res.data.status == 'OK') {
                        let tmpdata = res.data.items
                        tmpdata.forEach(function (v) {
                            _self.monthlyAppoint.push(v.appoint)
                            _self.monthlyCheck.push(v.check)
                            _self.monthlyFinish.push(v.finish)
                            _self.monthlyDate.push(v.date)
                        })
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
                .then(() => {
                    _self.$vs.loading.close()
                })
        },
        // 回傳 預約人數 & 報到人數 & 完成人數
        calCountByMonth(date) {
            var _self = this
            for (let i = 0; i < _self.monthlyDate.length; i++) {
                if (_self.monthlyDate[i] == date) {
                    if (_self.monthlySelection == 1) {
                        return _self.monthlyAppoint[i]
                    } else if (_self.monthlySelection == 2) {
                        return _self.monthlyCheck[i]
                    } else if (_self.monthlySelection == 3) {
                        return _self.monthlyFinish[i]
                    }
                }
            }
        },
        // 上個月 更改 month & year
        LastMonth() {
            switch (this.month) {
                case '01':
                    this.month = '12'
                    this.year--
                    break
                case '02':
                    this.month = '01'
                    break
                case '03':
                    this.month = '02'
                    break
                case '04':
                    this.month = '03'
                    break
                case '05':
                    this.month = '04'
                    break
                case '06':
                    this.month = '05'
                    break
                case '07':
                    this.month = '06'
                    break
                case '08':
                    this.month = '07'
                    break
                case '09':
                    this.month = '08'
                    break
                case '10':
                    this.month = '09'
                    break
                case '11':
                    this.month = '10'
                    break
                case '12':
                    this.month = '11'
                    break
            }
        },
        // 下個月 更改 month & year
        NextMonth() {
            switch (this.month) {
                case '01':
                    this.month = '02'
                    break
                case '02':
                    this.month = '03'
                    break
                case '03':
                    this.month = '04'
                    break
                case '04':
                    this.month = '05'
                    break
                case '05':
                    this.month = '06'
                    break
                case '06':
                    this.month = '07'
                    break
                case '07':
                    this.month = '08'
                    break
                case '08':
                    this.month = '09'
                    break
                case '09':
                    this.month = '10'
                    break
                case '10':
                    this.month = '11'
                    break
                case '11':
                    this.month = '12'
                    break
                case '12':
                    this.month = '01'
                    this.year++
                    break
            }
        },
        // 取主選單所有通知數量
        setMainSidebarQuantityTips() {
            this.$store.dispatch('getMembersTotal', { partner_id: this.partnerid })
            this.$store.dispatch('getCountByTodo')
            this.$store.dispatch('getCountByUnreadMsg')
            this.$store.dispatch('getCountByUnreadNotify')
            this.$store.dispatch('getCountByUnreadSchedule')
            this.$store.dispatch('fetchOpinion', 1)
            this.$store.dispatch('fetchMyOpinion', 1)
            // this.$store.dispatch('fetchApptUndoneQuantity', this.partnerid)
            this.$store.dispatch('getTodayQueuesUndoneQuantity')
            this.$store.dispatch('fetchSRQuantity', { partnerid: this.partnerid })
            // this.$store.dispatch('fetchAgentReservation', {'partnerid': this.partnerid})
            // this.$store.dispatch('fetchOtherDayReservation', {'partnerid': this.partnerid})
            // this.$store.dispatch('fetchSpecialReservation', {'partnerid': this.partnerid})
        },
        // 取合作夥伴名字
        getPartnerName() {
            if (this.partnerid == 32) {
                return this.$t('partner.KeelungChangGungMemorialHospital')
            } else if (this.partnerid == 1575) {
                return this.$t('partner.AllianceTitle')
            } else if (this.partnerid == 2085 || this.partnerid == 1577) {
                return this.$t('partner.ChengHsinTitle')
            } else if (this.partnerid == 1055 || this.partnerid == 1574) {
                return this.$t('partner.CLCTitle')
            } else if (this.partnerid == 4148 || this.partnerid == 4146) {
                return this.$t('partner.PingChrisTitle')
            } else if (this.partnerid == 4150) {
                return this.$t('partner.CamiansaintTitle')
            } else {
                return this.$t('partner.CurdrTitle')
            }
        },
    },
    components: {
        VueApexCharts,
        homeSetting,
    },
}
</script>

<style>
#weeknum > div.vx-card__collapsible-content.vs-con-loading__container > div > div.p-6.pb-0 > div > span,
#weeknum2 > div.vx-card__collapsible-content.vs-con-loading__container > div > div.p-6.pb-0 > div > span {
    font-size: x-large;
}
#content-area > div.content-wrapper > div > div > div > div.vx-row > div:nth-child(2) > div > div.vx-card__collapsible-content.vs-con-loading__container > div > div.p-6.pb-0 > div > span {
    font-size: x-large;
}
.text-grey {
    color: #3c3c3c !important;
}
.el-calendar__header .el-calendar__title {
    position: absolute;
    left: 50%;
    transform: translate(-45%);
    font-size: 20px;
    color: black;
}
.el-calendar__button-group {
    width: 100%;
}

.el-button-group {
    display: flex;
    justify-content: space-between;
}

.el-button-group::after,
.el-button-group::before {
    content: unset;
}
/* 用於判斷要不要顯示今日的按鈕 */
.el-button-group > .el-button:not(:first-child):not(:last-child) {
    display: none;
}
/* 把上個月按鈕替換為< */
.el-button-group > .el-button:first-child:before {
    content: '<';
    font-size: 20px;
}
/* 把下個月按鈕替換為> */
.el-button-group > .el-button:last-child:before {
    content: '>';
    font-size: 20px;
}
.el-button-group > .el-button:first-child span,
.el-button-group > .el-button:last-child span {
    display: none;
}
p.fontStyle {
    font-size: 20px;
}
</style>
